<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' v-model='valid' lazy-validation>
              <v-row>
                <v-col cols='6' lg='6' md='6' sm='6' xl='6' xs='6'>
                  <p class='font-weight-bold'>First Name</p>
                  <v-text-field
                    v-model='inputs.first_name'
                    :rules='firstNameRules'
                    dense
                    outlined
                    placeholder='John Mayer'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='6' lg='6' md='6' sm='6' xl='6' xs='6'>
                  <p class='font-weight-bold'>Last Name</p>
                  <v-text-field
                    v-model='inputs.last_name'
                    :rules='lastNameRules'
                    dense
                    outlined
                    placeholder='John Mayer'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Administrator Name</p>
                  <v-text-field
                    v-model='inputs.user_name'
                    :rules='userNameRules'
                    dense
                    outlined
                    placeholder='User Name'
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <p class='font-weight-bold'>Email Address</p>
                  <v-text-field
                    v-model='inputs.email'
                    :rules='emailRules'
                    dense
                    outlined
                    placeholder='email@email.com'
                    required
                    type='email'
                  ></v-text-field>
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <!-- <v-col cols="6" sm="6" xs="6" md="6" lg="6" xl="6"> -->
                  <p class='font-weight-bold'>Profile</p>
                  <v-autocomplete
                    v-model='inputs.user_profile_id'
                    :disabled='disabled'
                    :items='itemsProfile'
                    :rules='userProfileRules'
                    dense
                    outlined
                    placeholder='Profile'
                    required
                    @change='getEntity'
                  ></v-autocomplete>
                  <!-- </v-col> -->
                </v-col>
                <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                  <!-- <v-col cols="6" sm="6" xs="6" md="6" lg="6" xl="6"> -->
                  <p class='font-weight-bold'>Entity</p>
                  <v-autocomplete
                    v-model='inputs.entity'
                    :disabled='disabled'
                    :items='itemsEntity'
                    :rules='entityRules'
                    dense
                    outlined
                    placeholder='Entity'
                    required
                  ></v-autocomplete>
                  <!-- </v-col> -->
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-center'>
          <v-btn
            :disabled='loading'
            class='px-14 mx-4 font-weight-bold'
            color='primary'
            large
            outlined
            @click='closeDialog'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='loading'
            :loading='loading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='save'
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import user from '../../../services/user'

export default {
  computed: {
    crud() {
      this.initialLoad()
      return this.$store.getters.crud
    },
    employeeId() {
      return this.$store.getters.crud.data.employee_id
    }
  },
  data: () => ({
    inputs: {
      first_name: '',
      last_name: '',
      user_name: '',
      email: '',
      user_profile_id: '',
      entity: ''
    },
    loading: false,
    valid: false,
    itemsProfile: [],
    itemsEntity: [],
    userNameRules: [(v) => !!v || 'User name is required'],
    firstNameRules: [(v) => !!v || 'User name is required'],
    lastNameRules: [(v) => !!v || 'User name is required'],
    emailRules: [
      (v) => !!v || 'Email Address is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    userProfileRules: [(v) => !!v || 'Profile is required'],
    entityRules: [(v) => !!v || 'Entity is required'],

    disabled: false
  }),
  methods: {
    async initialLoad() {
      if (this.$store.getters.crud.id) {
        await this.getData()
      }
      this.itemsProfile = await user.getProfile()
      await this.setEmployee()
    },
    async setEmployee() {
      if (this.$store.getters.crud.data) {
        if (this.$store.getters.crud.data.employee_id) {
          const employee_id = this.$store.getters.crud.data.employee_id
          this.disabled = true
          let aux = this.inputs
          aux.user_profile_id = 2
          this.inputs = aux
          await this.getEntity()
          aux = this.inputs
          aux.entity = employee_id
          this.inputs = aux
        }
      }
    },
    async getEntity() {
      this.inputs.entity = ''
      if (this.inputs.user_profile_id === '') {
        return
      }
      try {
        this.itemsEntity = await user.getEntity(this.inputs.user_profile_id)
      } catch (e) {
        // empty
      }
    },
    async save() {
      if (this.$store.getters.crud.id) {
        await this.updated()
      } else {
        await this.store()
      }
    },
    async getData() {
      try {
        const res = await user.show(this.$store.getters.crud.id)
        let aux = {
          first_name: res.first_name,
          last_name: res.last_name,
          user_name: res.user_name,
          email: res.email,
          user_profile_id: res.user_profile_id
        }
        this.inputs = aux
        await this.getEntity()
        aux.entity = res.entity
        this.inputs = aux
      } catch (e) {
        // empty
      }
    },
    async store() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const res = await user.store(this.inputs)
          if (res?.success) {
            this.closeDialog()
            await this.$store.dispatch('alert', {
              show: true,
              color: 'green',
              title: 'Success message!',
              message: res.message,
              response: true
            })
            this.$emit('update')
            this.loading = false
          } else {
            this.closeDialog()
            this.loading = false
          }
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async updated() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const res = await user.updated(
            this.$store.getters.crud.id,
            this.inputs
          )
          this.closeDialog()
          await this.$store.dispatch('alert', {
            show: true,
            color: 'green',
            title: 'Success message!',
            message: res.message,
            response: true
          })
          this.$emit('update')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    closeDialog() {
      this.$refs.form.reset()
      this.disabled = false
      this.$store.dispatch('crud', {
        dialog: false,
        title: '',
        id: null
      })
    }
  }
}
</script>
<style>
.v-autocomplete__content {
  max-height: 200px !important;
}
</style>
